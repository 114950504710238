// admin, owner, manager, staff, partner, headless
export default [
  {
    component: 'CNavItem',
    name: 'แดชบอร์ด',
    to: '/dashboard',
    icon: 'cil-speedometer',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavTitle',
    name: 'ข้อมูลการเงิน',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavItem',
    name: 'ฝาก & ถอน',
    to: '/banking/transactions',
    icon: 'cil-money',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavItem',
    name: 'คืนยอดเสีย',
    to: '/banking/returncredit',
    icon: 'cil-balance-scale',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavTitle',
    name: 'การตั้งค่า',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavItem',
    name: 'เว็บไซต์',
    to: '/setting/website',
    icon: 'cib-electron',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'บัญชี',
    to: '/setting/banking',
    icon: 'cil-bank',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'โรบอท',
    to: '/setting/robot',
    icon: 'cib-probot',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavTitle',
    name: 'รายงาน',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavItem',
    name: 'รายงานโรบอท',
    to: '/report/robot',
    icon: 'cil-folder-open',
    role: ['admin', 'owner'],
  },
  {
    component: 'CNavTitle',
    name: 'เอกสาร',
    role: ['admin', 'owner', 'headless'],
  },
  {
    component: 'CNavItem',
    name: 'รายการเอกสาร',
    to: '/document/list',
    icon: 'cil-list-rich',
    role: ['admin', 'owner', 'headless'],
  },
]
