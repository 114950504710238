import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/banking',
        name: 'Banking',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/banking/returncredit',
        children: [
          {
            path: '/banking/transactions/:webID?',
            name: 'Transactions',
            component: () => import('@/views/_banking/Transaction.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/banking/returncredit',
            name: 'Return Credit',
            component: () => import('@/views/_banking/ReturnCredit.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/setting',
        name: 'Setting',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/setting/robot',
        children: [
          {
            path: '/setting/robot',
            name: 'Robot setting',
            component: () => import('@/views/_setting/Robot.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/website',
            name: 'Website setting',
            component: () => import('@/views/_setting/Website.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/setting/banking/:webID?',
            name: 'Banking setting',
            component: () => import('@/views/_setting/Banking.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/report',
        name: 'Report',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/report/robot',
        children: [
          {
            path: '/report/robot',
            name: 'Report Robot',
            component: () => import('@/views/_report/Robot.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/document',
        name: 'Documents',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/document/list',
        children: [
          {
            path: '/document/list',
            name: 'Document List',
            component: () => import('@/views/_document/ListOfDocuments.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: { guest: true },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
        meta: { guest: true },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/authenticated'] != null) {
      next()
      return
    }
    next('/pages/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['auth/authenticated'] != null) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
