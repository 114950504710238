<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <!-- <CIcon
        custom-class-name="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      /> -->
      <CImage v-show="webName == 'headless'" fluid :src="logoHeadless" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { mapActions, mapGetters, useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'

// import { logoNegative } from '@/assets/brand/logo-negative'
// import logoNegative from '@/assets/brand/header-logo-03.png'
import logoHeadless from '@/assets/brand/header-logo-headless.png'

import { sygnet } from '@/assets/brand/sygnet'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data() {
    return {
      optWebAgent: [],
      webName: '',
    }
  },
  methods: {
    ...mapActions({
      tokenExpired: 'auth/tokenExpired',
    }),
    navigateTo(route) {
      this.$router.push(route)
    },
  },
  created() {
    this.webName = 'headless'
    document.title = this.user.web_name.toUpperCase() + ' Robot Headless'
  },
  setup() {
    const store = useStore()
    return {
      logoHeadless,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
}
</script>
